$primary-color: #d67a8d;
$secondary-color: #fc2853;
$secondary-color-bg: #ffe9ee;

$error-color: #ff4f4f;

$bg-color: #fffbfe;
//$bg-color: #fffcf4;
$header-color: rgba(255, 255, 255, 0.81);
$footer-color: rgba(255, 255, 255, 0.81);

$white: #ffffff;
$white-1: #f5f5f5;
$white-2: #f3f3f3;
$white-3: #efefef;

$text-dark-1: #4d4d4e;
$text-dark-2: #5e5e5f;
$text-dark-3: #6b6b6c;
$text-dark-4: #868687;
$text-dark-5: #a4a4a5;
$text-black: #000;

$input-bg-color: #2b2b2b;
$input-border-color: #ccc;
$input-placeholder-color: #adadad;
$sidebar-bg-color: rgba(24, 24, 24, 0.87);
$sidebar-icon-color-inactive: $text-dark-5;

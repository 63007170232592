@import '../styles/colors';

.contact-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  h2 {
    margin-bottom: 20px;
    font-size: 44px;
    font-family: 'Instrument Serif', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: italic;
    line-height: 64px;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      font-size: 14px;
      font-weight: 500;
      display: block;
      margin-bottom: 5px;
      span {
        font-size: 12px;
        color: $text-dark-4;
        font-weight: 300;
      }
    }

    .checkbox-group {
      .checkbox-group-item {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        span {
          flex: 1;
          font-size: 14px;
          font-style: italic;
        }
        input {
          width: 36px;
          height: 18px;
          cursor: pointer;
        }
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $input-border-color;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 5px;
      box-sizing: border-box;

      &::placeholder {
        color: $input-placeholder-color;
      }
    }

    textarea {
      height: 150px;
      box-sizing: border-box;
    }

    .error {
      color: $error-color;
      font-size: 12px;
    }
  }

  .name-fields {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    & > div {
      flex: 1;
    }
    input {
      width: 100%;
      box-sizing: border-box;
    }
  }

  button {
    border: 1px solid $input-border-color;
    color: $text-black;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    background-color: $white;

    &:hover {
      background-color: $white-1;
    }
  }
}

@import '../styles/constants';
@import '../styles/colors';

.home-page {
  width: 80%;
  margin: 0 auto;
  max-width: $body-max-width + 84;
  @media only screen and (max-width: 1200px) {
    width: 90% !important;
  }
  @media only screen and (max-width: 650px) {
    width: 100% !important;
    padding: 0 16px;
  }
  .absolute {
    position: absolute;
    top: 84px;
    width: 40%;
    @media only screen and (max-width: 650px) {
      width: 100% !important;
      position: relative;
      top: 36px;
    }
    .heading,
    .heading-2 {
      font-size: 84px;
      font-family: 'Instrument Serif', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: italic;
      @media only screen and (max-width: 1400px) {
        font-size: 64px !important;
      }
      @media only screen and (max-width: 1200px) {
        font-size: 54px !important;
      }
      @media only screen and (max-width: 900px) {
        font-size: 44px !important;
      }
      @media only screen and (max-width: 700px) {
        font-size: 36px !important;
      }
    }
    @media only screen and (max-width: 650px) {
      .heading {
        display: none !important;
      }
      .heading-2 {
        display: block !important;
      }
    }
    @media only screen and (min-width: 650px) {
      .heading {
        display: block !important;
      }
      .heading-2 {
        display: none !important;
      }
    }
    .desc {
      font-size: 22px;
      font-family: 'Playfair Display', serif;
      font-optical-sizing: auto;
      font-style: normal;
      margin-top: 24px;
      @media only screen and (max-width: 900px) {
        font-size: 20px !important;
      }
      @media only screen and (max-width: 650px) {
        font-size: 18px !important;
      }
    }
  }
  .jiye-img {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(100vh - $header-height);
    @media only screen and (max-width: 650px) {
      display: none;
    }
    img {
      width: 520px;
      align-self: end;
      filter: drop-shadow(32px 32px 0 #ffcfdb);
      @media only screen and (max-width: 1400px) {
        width: 480px;
      }
      @media only screen and (max-width: 900px) {
        width: 400px;
      }
      @media only screen and (max-width: 700px) {
        width: 360px;
      }
    }
  }
  .reviews {
    display: flex;
    align-items: center;
    margin-top: 184px;
    background: $secondary-color-bg;
    border-radius: 8px;
    @media only screen and (max-width: 650px) {
      flex-direction: column;
      margin-top: 84px;
      .left {
        margin-right: 0 !important;
      }
    }
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-right: 20px;
      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .review-box {
        padding: 20px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 1.6;
        font-family: 'Playfair Display', serif;
        position: relative;
        text-align: justify;
        .author {
          font-size: 16px;
          font-weight: bold;
          color: $primary-color;
          margin-top: 24px;
          text-align: start;
        }
      }
    }
  }
}

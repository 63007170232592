@import '../styles/constants';
@import '../styles/colors';

.writing-page {
  max-width: $body-max-width;
  margin: 0 auto;
  .publications-section {
    max-width: $body-max-width;
    margin: 0 auto;
    padding: 24px 0;
    .section-heading {
      text-align: center;
      font-size: 36px;
      margin: 16px 0;
    }
    .carousell {
      display: flex;
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        .c-item {
          margin-bottom: 32px;
        }
      }
      .c-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $text-black;
        img {
          width: 80%;
          max-width: 220px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease-in-out;
          border-radius: 16px;
          &:hover {
            transform: scale(1.05);
          }
        }
        .first {
          display: inline-block;
          font-size: 22px;
          margin-top: 16px;
          font-family: 'Roboto Flex', serif;
          font-optical-sizing: auto;
          font-style: normal;
        }
        .last {
          display: inline-block;
          font-size: 18px;
          margin-top: 16px;
          font-family: 'Roboto Flex', serif;
          font-optical-sizing: auto;
          font-style: normal;
          font-weight: 300;
          color: $text-dark-4;
        }
      }
    }
  }
  .section-heading {
    text-align: center;
    font-size: 36px;
  }
  .review-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $body-max-width;
    margin: 0 auto;
    border-bottom: 1px solid $white-2;
    padding: 12px 0;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      padding: 24px 0;
      .section-heading {
        align-items: center;
        justify-content: center;
        width: 100% !important;
      }
      .section-text {
        display: none !important;
      }
    }
    img {
      width: 64px;
      height: 64px;
      margin-right: 24px;
      border-radius: 8px;
    }
    .section-heading {
      display: flex;
      font-size: 36px;
      font-style: normal;
      //width: 340px;
      cursor: pointer;
      a {
        text-decoration: none;
        color: $text-black;
        display: flex;
      }
    }
    .section-text {
      font-size: 18px;
      a {
        text-decoration: none;
        color: $text-black;
      }
    }
  }
  .comissions {
    a {
      text-decoration: none;
    }
    &__list {
      list-style: none;
      padding: 0;
    }

    &__item {
      margin-bottom: 20px;
      text-align: center;
    }

    &__title {
      font-size: 20px;
      color: $text-black;
      margin: 0;
      font-weight: 400;
      @media only screen and (max-width: 700px) {
        font-size: 18px !important;
      }
    }

    &__description {
      font-size: 14px;
      color: $text-dark-3;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

@import 'colors';
@import 'constants';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg-color !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  .flex-auto {
    flex: auto;
  }
}

#jiye-main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  .jiye-container {
    margin-top: $header-height;
    position: relative;
    flex: 1;
    @media only screen and (max-width: 700px) {
      margin-top: $header-height-mobile !important;
    }
  }
}

.section-heading {
  font-size: 54px;
  font-family: 'Instrument Serif', serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
  line-height: 64px;
}
.section-text {
  font-size: 22px;
  font-family: 'Roboto', serif;
  font-optical-sizing: auto;
  font-style: normal;
  padding: 16px 32px;
  text-align: justify;
  p {
    line-height: 30px;
  }
  p:first-child::first-letter {
    line-height: 54px;
    font-size: 54px;
    float: left;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 700px) {
  .section-heading {
    font-size: 36px;
  }
  .section-text {
    font-size: 18px;
    padding: 0 16px;
  }
}
@media only screen and (max-width: 550px) {
  .section-heading {
    font-size: 32px !important;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

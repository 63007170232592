@import '../styles/constants';

.about-page {
  display: flex;
  width: 100%;
  max-width: $body-max-width + 84;
  margin: 0 auto;
  padding: 24px;
  @media only screen and (max-width: 700px) {
    display: block;
    padding: 24px;
    .right-section {
      .brand-image {
        margin-top: 14px !important;
      }
    }
  }
  .left-section {
    flex: 4;
  }
  .right-section {
    flex: 4;
    .brand-image {
      margin-top: 94px;
      width: 100%;
    }
  }
}

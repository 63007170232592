@import '../styles/colors';
@import '../styles/constants';

#jiye-footer {
  background-color: $footer-color;
  z-index: 100;
  text-align: center;
  padding: 36px 0;
  width: 100%;
  .button {
    display: inline-block;
    width: 64px;
    text-align: center;
    padding: 4px 0;
    cursor: pointer;
    font-size: 14px;
    a {
      color: $sidebar-icon-color-inactive;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: $primary-color;
      }
    }
    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }
}

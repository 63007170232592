@import '../styles/constants';
@import '../styles/colors';

.books-page {
  .top-section {
    width: 100%;
    background: linear-gradient(
      180deg,
      #e5c7c3,
      #d7a9a2,
      #c79690,
      #b6847d,
      #9c716b,
      #84625c
    );
    background-size: cover;
    margin: 0;
    padding: 36px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 400px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      @media only screen and (max-width: 700px) {
        max-width: 400px;
      }
      @media only screen and (max-width: 500px) {
        max-width: 200px;
      }
    }
  }
  .section-heading {
    text-align: center;
    font-size: 36px;
  }
  .review-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $body-max-width;
    margin: 0 auto;
    border-bottom: 1px solid $white-2;
    padding: 12px 0;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      padding: 24px 0;
      .section-heading {
        align-items: center;
        justify-content: center;
        width: 100% !important;
      }
      .section-text {
        display: none !important;
      }
    }
    img {
      width: 64px;
      height: 64px;
      margin-right: 24px;
      border-radius: 8px;
    }
    .section-heading {
      display: flex;
      font-size: 36px;
      font-style: normal;
      cursor: pointer;
      a {
        text-decoration: none;
        color: $text-black;
        display: flex;
      }
    }
    .section-text {
      a {
        text-decoration: none;
        color: $text-black;
      }
    }
  }
  .purchase-section {
    max-width: $body-max-width;
    margin: 0 auto;
    padding: 24px 0;
    .section-heading {
      text-align: center;
      font-size: 36px;
      margin: 16px 0;
    }
    .carousell {
      display: flex;
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        .c-item {
          margin-bottom: 32px;
        }
      }
      .c-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $text-black;
        img {
          width: 80%;
          max-width: 220px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease-in-out;
          border-radius: 16px;
          &:hover {
            transform: scale(1.05);
          }
        }
        span {
          display: inline-block;
          font-size: 22px;
          margin-top: 16px;
          font-family: 'Roboto Flex', serif;
          font-optical-sizing: auto;
          font-style: normal;
        }
      }
    }
  }
}

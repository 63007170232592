@import '../styles/colors';
@import '../styles/constants';

#jiye-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  backdrop-filter: blur(16px);
  background-color: $header-color;
  height: $header-height;
  z-index: 100;
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    height: $header-height-mobile !important;
  }
  .header {
    max-width: $body-max-width;
    margin: 0 auto;
    padding: 12px 0 6px;
    text-align: center;
    a {
      text-decoration: none !important;
    }
    .brand {
      color: $text-black;
      font-size: 44px;
      font-family: 'Playfair Display', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      margin-top: 16px;
      @media only screen and (max-width: 700px) {
        font-size: 32px !important;
        margin-top: 8px;
      }
    }
    .sub-brand {
      color: $text-black;
      font-weight: 300;
      font-size: 18px;
      font-family: 'Roboto Flex', serif;
      font-optical-sizing: auto;
      font-style: normal;
      margin-top: 8px;
      span {
        display: inline-block;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .navigation {
    margin: 24px 15%;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 700px) {
      margin: 16px 8px;
    }
    a {
      text-decoration: none;
      color: $text-black;
      display: block;
      flex: auto;
      &:hover {
        color: $primary-color;
      }
    }
    .nav-item {
      display: block;
      font-size: 18px;
      font-family: 'Roboto Flex', serif;
      font-weight: 300;
    }
    .active-link {
      color: $primary-color !important;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

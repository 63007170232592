@import '../styles/constants';
@import '../styles/colors';

.events-page {
  max-width: $body-max-width;
  margin: 0 auto;
  .placeholder {
    font-family: 'Instrument Serif', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: italic;
    font-size: 20px;
  }
  .past-events {
    padding: 20px;

    &__header {
      font-size: 44px;
      margin-bottom: 20px;
      font-family: 'Instrument Serif', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: italic;
      line-height: 64px;
      @media only screen and (max-width: 700px) {
        font-size: 32px !important;
      }
    }

    &__list {
      list-style: none;
      padding: 0;
    }

    &__item {
      margin-bottom: 20px;
      border-left: 4px solid $primary-color;
      padding-left: 15px;
    }

    &__title {
      font-size: 20px;
      color: $text-black;
      margin: 0;
      font-weight: 400;
      @media only screen and (max-width: 700px) {
        font-size: 18px !important;
      }
    }

    &__description {
      font-size: 14px;
      color: $text-dark-3;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &__date {
      font-size: 14px;
      color: $text-dark-3;
    }

    &__item:hover {
      background-color: #f9f9f9;
    }
  }
  .ig-header {
    font-size: 24px;
    @media only screen and (max-width: 700px) {
      font-size: 18px !important;
    }
    font-family: 'Instrument Serif', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: italic;
    line-height: 44px;
    text-align: center;
  }
}
